<template>
  <div>
    <Heard />
    <!-- <Tip /> -->
    <div class="container person-box">
      <Aside />
      <div class="right-box">
        <div class="headbox">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            action="http://ssck.hrclass.com.cn:8083/file/upLoad"
            :on-success="editheadimg"
          >
            <img
              style="width: 160px; height: 160px; border-radius: 50%"
              v-if="imageUrl"
              :src="imageUrl"
            />
            <p class="size"><i class="el-icon-picture"></i> 修改头像</p>
          </el-upload>

          <div class="btn" @click="edithead">保存修改</div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { getUser } from "@/plugins/auth.js";
import { api_updateUserImg, api_queryUser } from "@/apis/apis";

import Heard from "@/components/Heard.vue";
// import Tip from "@/components/Tip.vue";
import Aside from "@/components/Aside.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Heard,
    // Tip,
    Aside,
    Footer,
  },
  data() {
    return {
      imageUrl: "",
      user: {},
      userinfo: {},
    };
  },
  created() {
    this.user = getUser();
    this.getinfo();
  },
  methods: {
    getinfo() {
      api_queryUser({
        id: this.user.id,
      }).then((res) => {
        console.log(res.data);
        this.userinfo = res.data.data;
        this.imageUrl = res.data.data.img;
      });
    },
    //修改头像
    editheadimg(e) {
      console.log(e);
      this.imageUrl = e.data.url;
    },

    edithead() {
      api_updateUserImg({
        id: this.user.id,
        img: this.imageUrl,
      }).then((res) => {
        console.log(res);
        this.getinfo();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.person-box {
  display: flex;
  .right-box {
    flex: 1;
    border: 1px solid #f2f2f2;
    .headbox {
      width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      .size {
        margin-top: 20px;
      }
      .img {
        width: 160px;
        height: 160px;
      }
    }

    .btn {
      height: 36px;
      width: 120px;
      line-height: 36px;
      text-align: center;
      margin: 60px;
      background-color: #2d69f9;
      color: #fff;
    }
  }
}
</style>